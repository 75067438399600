import { type VariantProps, cva } from "class-variance-authority";
export const inputVariants = cva(
  "peer w-full border bg-white text-base text-primary transition-[border] focus:outline-none rounded-none",
  {
    variants: {
      variant: {
        default:
          "placeholder:capitalize placeholder:text-base placeholder:text-neutral-black-200 placeholder:font-normal",
        floating: "placeholder:text-transparent",
      },
      size: {
        default: "h-10 py-2.5",
        md: "h-12 py-3.5",
      },
      color: {
        default: "border-neutral-white-200 focus:border-primary",
        error: "border-semantic-red-700 ring-1 ring-semantic-red-700",
      },
    },
    compoundVariants: [
      {
        size: "default",
        variant: "floating",
        class:
          "focus:pt-3.5 focus:pb-2 [&:not(:placeholder-shown)]:pt-3.5 [&:not(:placeholder-shown)]:pb-1",
      },
      {
        size: "md",
        variant: "floating",
        class:
          "focus:pt-5.5 focus:pb-1.5 [&:not(:placeholder-shown)]:pt-5.5 [&:not(:placeholder-shown)]:pb-1.5",
      },
    ],
    defaultVariants: {
      size: "default",
      variant: "default",
      color: "default",
    },
  },
);

export type InputVariants = VariantProps<typeof inputVariants>;
